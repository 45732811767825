import React, { useContext, useEffect, useState } from "react";
import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import { chain } from "../components/SmallComponents/Images";
import { AppContext } from "../utils";
import {
  tokenReadFunction,
  tokenWriteFunction,
} from "../ConnectivityAssets/hooks";
import { formatUnits } from "viem";
import { FaTelegramPlane } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaMediumM } from "react-icons/fa";
import flash from "../Images/flash.png";
import mev from "../Images/mev.png";
import sniper from "../Images/sniper.png";
import { fetchBalance } from "@wagmi/core";
import {
  StyledButton,
  ToastNotify,
} from "../components/SmallComponents/AppComponents";
import Loading from "../components/SmallComponents/loading";

function HeroSection() {
  const matches = useMediaQuery("(max-width:950px)");
  const { account, isConnected } = useContext(AppContext);
  const [balance, setBalance] = useState(0);
  const [claimed, setClaimed] = useState(0);
  const [claimable, setClaimable] = useState(0);
  const [distributed, setDistributed] = useState(0);
  const [loading, setloading] = useState(false);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });

  const refreshRewards = async () => {
    try {
      // Total Revenue Added
      let totalrewardAdded = await tokenReadFunction("totalAddedReward");
      console.log(
        +formatUnits(totalrewardAdded.toString(), 18),
        "totalAddedReward"
      );
      setBalance(+formatUnits(totalrewardAdded.toString(), 18));

      // Total Users Claimed
      let getClaimedToken = await tokenReadFunction("totalUsersClaimed");
      console.log(getClaimedToken, "totalUsersClaimed");
      setClaimed(getClaimedToken);

      // Total Distributed Eth
      let totalDistributed = await tokenReadFunction("totalClaimedReward");
      console.log(
        +formatUnits(totalDistributed.toString(), 18),
        "totalClaimedReward"
      );
      setDistributed(+formatUnits(totalDistributed.toString(), 18));

      // Users Reward
      let getClaimableToken = await tokenReadFunction("userBalance", [
        account,
      ]);
      console.log(
        +formatUnits(getClaimableToken.toString(), 18),
        "userBalance"
      );
      setClaimable(+formatUnits(getClaimableToken.toString(), 18));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        // Total Revenue Added
        let totalrewardAdded = await tokenReadFunction("totalAddedReward");
        console.log(
          +formatUnits(totalrewardAdded.toString(), 18),
          "totalAddedReward"
        );
        setBalance(+formatUnits(totalrewardAdded.toString(), 18));

        // Total Users Claimed
        let getClaimedToken = await tokenReadFunction("totalUsersClaimed");
        console.log(getClaimedToken, "totalUsersClaimed");
        setClaimed(getClaimedToken);

        // Total Distributed Eth
        let totalDistributed = await tokenReadFunction("totalClaimedReward");
        console.log(
          +formatUnits(totalDistributed.toString(), 18),
          "totalClaimedReward"
        );
        setDistributed(+formatUnits(totalDistributed.toString(), 18));
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (account) {
          // // Total Revenue Added
          // let totalrewardAdded = await tokenReadFunction("totalAddedReward");
          // console.log(
          //   +formatUnits(totalrewardAdded.toString(), 18),
          //   "totalAddedReward"
          // );
          // setBalance(+formatUnits(totalrewardAdded.toString(), 18));

          // // Total Users Claimed
          // let getClaimedToken = await tokenReadFunction("totalUsersClaimed");
          // console.log(getClaimedToken, "totalUsersClaimed");
          // setClaimed(getClaimedToken);

          // Users Reward
          let getClaimableToken = await tokenReadFunction("userBalance", [
            account,
          ]);
          console.log(
            +formatUnits(getClaimableToken.toString(), 18),
            "userBalance"
          );
          setClaimable(+formatUnits(getClaimableToken.toString(), 18));

          // // Total Distributed Eth
          // let totalDistributed = await tokenReadFunction("totalClaimedReward");
          // console.log(
          //   +formatUnits(totalDistributed.toString(), 18),
          //   "totalClaimedReward"
          // );
          // setDistributed(+formatUnits(totalDistributed.toString(), 18));
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [account]);

  const claimhandler = async () => {
    try {
      if (account) {
        setloading(true);
        await tokenWriteFunction("claim");
        setloading(false);
        setAlertState({
          open: true,
          message: "Claim confirmed!!!",
          severity: "success",
        });

        await refreshRewards()

      } else {
        throw new Error('Please connect wallet');
      }

    } catch (error) {
      console.error('claimError:', error);
      setloading(false);
      let errorMessage;
      if (error?.message === 'Please connect wallet') {
        errorMessage = 'Please connect wallet'
      } else if (error.shortMessage) {
        errorMessage = error?.shortMessage
      } else {
        errorMessage = error?.message
      }
      // const errorMessage = error?.shortMessage;
      setAlertState({
        open: true,
        message: errorMessage,
        severity: "error",
      });
    }
  };

  return (
    <Box pb={10}>
      <Loading loading={loading} />
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />

      <Container maxWidth="lg">
        <Grid container spacing={7} mt={1}>
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              sx={{
                width: "100%",
                height: "332px",
                background: "#16161D",
                borderRadius: "9px",
                boxShadow: "#ffffffa1 0px 0px 8px 1px",
                border: "2px solid #CE4BE0",
              }}
            >
              <Box
                width="90%"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontWeight: "700",
                    fontSize: "22px",
                    fontFamily: "Ubuntu",
                  }}
                >
                  Total Revenue Added
                </Typography>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mt={2}
                  sx={{
                    color: "#fff",
                    fontWeight: "700",
                    fontSize: "16px",
                    fontFamily: "Ubuntu",
                    width: "100%",
                    height: "38px",
                    p: 1,
                    background: "#242731",
                    borderRadius: "6px",
                  }}
                >
                  {parseFloat(balance).toFixed(2)} ETH
                </Box>
              </Box>
              <Box
                mt={4}
                width="90%"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontWeight: "700",
                    fontSize: "22px",
                    fontFamily: "Ubuntu",
                  }}
                >
                  Total Users Claimed
                </Typography>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mt={2}
                  sx={{
                    color: "#fff",
                    fontWeight: "700",
                    fontSize: "16px",
                    fontFamily: "Ubuntu",
                    width: "100%",
                    height: "38px",
                    p: 1,
                    background: "#242731",
                    borderRadius: "6px",
                  }}
                >
                  {parseFloat(claimed).toFixed(0)}
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              sx={{
                width: "100%",
                height: "332px",
                background: "#16161D",
                boxShadow: "#ffffffa1 0px 0px 8px 1px",
                border: "2px solid #CE4BE0",
                borderRadius: "9px",
              }}
            >
              <Box
                width="90%"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontWeight: "700",
                    fontSize: "22px",
                    fontFamily: "Ubuntu",
                  }}
                >
                  User's Reward
                </Typography>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mt={2}
                  sx={{
                    color: "#fff",
                    fontWeight: "700",
                    fontSize: "16px",
                    fontFamily: "Ubuntu",
                    width: "100%",
                    height: "38px",
                    p: 1,
                    background: "#242731",
                    borderRadius: "6px",
                  }}
                >
                  {parseFloat(claimable).toFixed(4)}
                </Box>
              </Box>
              <Box
                mt={4}
                width="90%"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontWeight: "700",
                    fontSize: "22px",
                    fontFamily: "Ubuntu",
                  }}
                >
                  Total Distributed ETH
                </Typography>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mt={2}
                  sx={{
                    color: "#fff",
                    fontWeight: "700",
                    fontSize: "16px",
                    fontFamily: "Ubuntu",
                    width: "100%",
                    height: "38px",
                    p: 1,
                    background: "#242731",
                    borderRadius: "6px",
                  }}
                >
                  {parseFloat(distributed).toFixed(4)}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="center" pt={5}>
          <StyledButton width="300px" onClick={claimhandler}>
            Claim
          </StyledButton>
        </Box>
        <Grid container spacing={3} mt={6}>
          <Grid item xs={12} md={4} className="leaf_moving">
            <Box
              display="flex"
              flexDirection="column"
              alignItems={matches && "center"}
            >
              <img
                src={flash}
                width={matches ? "85%" : "300px"}
                height={"350px"}
                alt=""
              />
              <Box
                mt={2}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: matches ? "85%" : "300px",
                  height: "110px",
                  background: "#16161D",
                  borderRadius: "9px",
                  boxShadow: "#ffffffa1 0px 0px 8px 1px",
                  border: "2px solid #CE4BE0",
                }}
              >
                <Box
                  width="90%"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontWeight: "700",
                      fontSize: "18px",
                      fontFamily: "Ubuntu",
                    }}
                  >
                    Revenue Generated
                  </Typography>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mt={2}
                    sx={{
                      color: "#fff",
                      fontWeight: "700",
                      fontSize: "16px",
                      fontFamily: "Ubuntu",
                      width: "100%",
                      height: "25px",
                      p: 1,
                      background: "#242731",
                      borderRadius: "6px",
                    }}
                  >
                    0.00 ETH
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            display="flex"
            flexDirection="column"
            alignItems={!matches && "center"}
            className="leaf_moving"
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems={matches && "center"}
            >
              <img
                src={mev}
                width={matches ? "85%" : "300px"}
                height={"350px"}
                alt=""
              />
              <Box
                mt={2}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: matches ? "85%" : "300px",
                  height: "110px",
                  background: "#16161D",
                  borderRadius: "9px",
                  boxShadow: "#ffffffa1 0px 0px 8px 1px",
                  border: "2px solid #CE4BE0",
                }}
              >
                <Box
                  width="90%"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontWeight: "700",
                      fontSize: "18px",
                      fontFamily: "Ubuntu",
                    }}
                  >
                    Revenue Generated
                  </Typography>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mt={2}
                    sx={{
                      color: "#fff",
                      fontWeight: "700",
                      fontSize: "16px",
                      fontFamily: "Ubuntu",
                      width: "100%",
                      height: "25px",
                      p: 1,
                      background: "#242731",
                      borderRadius: "6px",
                    }}
                  >
                    0.00 ETH
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            display="flex"
            flexDirection="column"
            alignItems={!matches && "flex-end"}
            className="leaf_moving"
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems={matches && "center"}
            >
              <img
                src={sniper}
                width={matches ? "85%" : "300px"}
                height={"350px"}
                alt=""
              />
              <Box
                mt={2}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: matches ? "85%" : "300px",
                  height: "110px",
                  background: "#16161D",
                  borderRadius: "9px",
                  boxShadow: "#ffffffa1 0px 0px 8px 1px",
                  border: "2px solid #CE4BE0",
                }}
              >
                <Box
                  width="90%"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontWeight: "700",
                      fontSize: "18px",
                      fontFamily: "Ubuntu",
                    }}
                  >
                    Revenue Generated
                  </Typography>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mt={2}
                    sx={{
                      color: "#fff",
                      fontWeight: "700",
                      fontSize: "16px",
                      fontFamily: "Ubuntu",
                      width: "100%",
                      height: "25px",
                      p: 1,
                      background: "#242731",
                      borderRadius: "6px",
                    }}
                  >
                    0.00 ETH
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="center" mt={5}>
          <a
            href="https://t.me/OCDPortal"
            style={{ textDecoration: "none" }}
            target="_blank"
            rel="noreferrer"
          >
            <Box
              mr={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                width: "55px",
                height: "55px",
                border: "1px solid #CE4BE0",
                borderRadius: "8px",
                cursor: "pointer",
              }}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  width: "35px",
                  height: "35px",
                  background: "#fff",
                  borderRadius: "50%",
                }}
              >
                <FaTelegramPlane style={{ color: "#000", fontSize: "22px" }} />
              </Box>
            </Box>
          </a>
          <a
            href="https://twitter.com/OnChainDynamics"
            style={{ textDecoration: "none" }}
            target="_blank"
            rel="noreferrer"
          >
            <Box
              mr={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                width: "55px",
                height: "55px",
                border: "1px solid #CE4BE0",
                borderRadius: "8px",
                cursor: "pointer",
              }}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  width: "35px",
                  height: "35px",
                  background: "#fff",
                  borderRadius: "50%",
                }}
              >
                <FaTwitter style={{ color: "#000", fontSize: "22px" }} />
              </Box>
            </Box>
          </a>
          <Box
            mr={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              width: "55px",
              height: "55px",
              border: "1px solid #CE4BE0",
              borderRadius: "8px",
              cursor: "pointer",
            }}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                width: "35px",
                height: "35px",
                background: "#fff",
                borderRadius: "50%",
              }}
            >
              <FaMediumM style={{ color: "#000", fontSize: "22px" }} />
            </Box>
          </Box>
          <a
            href="https://www.dextools.io/app/en/ether/pair-explorer/0x9e7065ce7e029d6a6d4936091547921a"
            style={{ textDecoration: "none" }}
            target="_blank"
            rel="noreferrer"
          >
            <Box
              mr={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                width: "55px",
                height: "55px",
                border: "1px solid #CE4BE0",
                borderRadius: "8px",
                cursor: "pointer",
              }}
            >
              <img src={chain} width="35px" height="35px" alt="" />
            </Box>
          </a>
        </Box>
      </Container>
    </Box>
  );
}

export default HeroSection;
